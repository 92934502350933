.select-wrapper {
  height: var(--form-element-height);
  border-radius: var(--form-element-border-radius);
  border: var(--form-element-border);
  border-color: var(--form-element-border-color);
  cursor: pointer;
  align-items: center;
  position: relative;
  min-width: 15ch;
  width: 100%;
  display: flex;

  // Custom arrow
  &::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    position: absolute;
    right: 10px;
    background-color: var(--button-blue);
  }
}

.native-select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  font-size: var(--form-element-font-size);
  color: var(--form-button-disabled-color);
  height: 70%;
  padding: 0 10px;

  option {
    font-size: inherit;

    &:disabled {
      color: rgb(181 180 180);
    }
  }
}

.select-option {
 margin: 15px 0;
}

.select__label {
  font-size: 14px;
  margin-left: 6px;
}
