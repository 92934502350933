@import "./fonts";

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

:root {
  /* Colors */
  --white: #fff;
  --black: #000;
  --button-blue: #1473e6;
  --button-blue-hover: #1162c4;
  --button-orange: #ff7518;
  --button-orange-hover: #e35335;
  --button-disabled-color: #7e7e7e;
  --button-disabled-bg-color: #d3d3d3;
  --button-shadow: rgb(0 0 0 / 50%) 0 5px 15px;
  --form-element-content-color: #7e7e7e;
  --form-element-border-color: #d3d3d3;
  --form-button-disabled-bg: #d3d3d3;
  --form-button-disabled-color: #7e7e7e;
  --form-stepper-bg-color: #e9e9e9;
  --form-list-type-color: #e9e9e9;
  --form-info-box-bg-color: #e6ecff;
  --form-info-box-shadow: rgb(149 157 165 / 30%) 0 4px 36px;
  --form-info-text-color: #8289a5;
  --form-select-list-element-hover: #f5f5f5;
  --side-bar-bg-color: #193d72;
  --side-bar-tooltip-bg-color: #032658;
  --error: red;

  /* Metrics */
  --form-element-height: 40px;
  --form-element-border-radius: 12px;
  --form-element-font-size: 14px;
  --form-element-border: 1.5px solid;
}
