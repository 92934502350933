@import "../../../../faktoring-ui/src/themes/mixins";

.dialog-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: none;

  &.dialog--open {
    display: block;
    background-color: rgba(0, 0, 0, 0.486);
    animation: fade-in 0.1s ease normal;
  }
}

.dialog-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  @include prevent-selection;

  border: none;
  border-radius: 12px;
  min-width: 565px;
  padding: 35px 45px;

  max-height: 95vh;

  z-index: 99999;
  background-color: var(--white);
}

.dialog__header {
  width: 100%;
  margin-bottom: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}

.dialog__body {
  margin: 20px 0;
}

.dialog__actions {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.dialog__actions-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog__actions-group--left {
  order: 1;
}

.dialog__actions-group--right {
  order: 2;
  text-align: right;
}

.dialog__close {
  position: absolute;
  top: 15px;
  right: 15px;
  box-shadow: none;
  width: 22px;
  height: 22px;
  font-size: 18px;
  line-height: 15px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-backdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}
