.flat-button {
  font-size: var(--form-element-font-size);
  border-radius: var(--form-element-border-radius);
  height: var(--form-element-height);
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 30px;
  font-weight: 600;
  background-color: var(--white);
  color: var(--button-blue);
  border: var(--form-element-border);
  border-color: var(--form-element-border-color);

  &:hover {
    background-color: var(--white);
  }

  &:disabled {
    background-color: var(--form-button-disabled-bg);
    color: var(--form-button-disabled-color);
    cursor: default;

    &:hover {
      background-color: var(--form-button-disabled-bg);
    }
  }
}

.flat-button__blue {
  border: none;
  background-color: var(--button-blue);
  color: var(--white);

  &:hover {
    background-color: var(--button-blue-hover);
  }
}

.flat-button__orange {
  border: none;
  background-color: var(--button-orange);
  color: var(--white);

  &:hover {
    background-color: var(--button-orange-hover);
  }
}

.flat-button__red {
  border: none;
  background-color: #ff3333;
  color: var(--white);

  &:hover {
    background-color: var(--button-orange-hover);
  }
}

.flat-button__green {
  border: none;
  color: white;
  background-color: #5FD068;

  &:hover {
    background-color: #5abd63;
  }
}