.client-request-preview {
  max-width: 800px;
  min-width: 700px;
}

.client-request-preview__flatbutton-wide {
  width: 100%;
}

.client-request-preview__additional_info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
}

.client-request-preview__additional-files {
  font-size: 14px;
}

.client-request-preview__invoice_list {
  padding: 30px;
  margin: 40px 0;
}

.client-request-preview__dialog-flatbuttons {
  display: flex;
  gap: 20px;
}

.client-request-preview__dialog-flatbutton {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-request-preview__additional-info {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.client-request-preview__message {
  max-height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #ff3333;
  color: var(--white);
  padding: 10px 20px;
  transition: box-shadow .3s;
  cursor: pointer;
}

.client-request-preview__message:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.client-request-preview__file {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.client-request-preview__additional_info-payment-data {
  margin-right: 10px;
}