.status-info {
  margin: 0 10px;
  padding: 6px 10px;
  border-radius: 10px;
  text-align: center;
}

.status-info__charged {
  color: rgb(62 167 150 / 100%);
  background-color: rgb(209 251 239 / 100%);
}

.status-info__realized {
  color: var(--white);
  background-color: rgb(97, 87, 233);
}

.status-info__accepted {
  color: rgb(18 67 170 / 100%);
  background-color: rgb(204 216 255 / 100%);
}

.status-info__placed {
  color: rgb(211 123 49 / 100%);
  background-color: rgb(254 226 202 / 100%);
}

.status-info__unknown {
  color: rgb(211 123 49 / 100%);
  background-color: rgb(254 226 202 / 100%);
}

.status-info__rejected {
  color: var(--white);
  font-weight: 600;
  background-color: #ff3333;
}