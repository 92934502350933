.input-checkbox {
  user-select: none;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--form-button-disabled-color);
  cursor: pointer;

  :hover {
    color: var(--button-blue);
  }

  input {
    display: none;
  }

  i {
    margin-left: 3px;
    color: var(--white);
  }

  input + div {
    width: 25px;
    height: 25px;
    border: var(--form-element-border);
    border-color: var(--form-element-border-color);
    border-radius: 5px;

    :hover {
      color: var(--white);
    }
  }

  input:checked + div {
    background-color: var(--button-blue);
    border-color: var(--button-blue);
  }
}
