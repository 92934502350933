@import "@faktoring/ui/src/themes/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 35px 0 10px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
}

.header__pages {
  display: flex;
  height: 100%;
}

.header__logo {
  @include center;

  height: 100%;
  min-width: 80px;
  margin-right: 25px;
  justify-self: flex-start;
}

.header__logo .icon {
  font-size: 40px;
  color: blue;
}

.header__logo .hidden {
  display: none;
}

.header__account-details {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header__portal button {
  @include center;
  width: 250px;
  height: 50px;
  border: none;
  border-radius: 40px;
  color: var(--white);
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
  }

  svg {
    font-size: 20px;
    height: 35px;
  }
}

.header__info {
  width: 45px;
  height: 45px;
  background-color: transparent;
  color: #555555;
  border: 1px solid #555555a2;
  border-radius: 50%;
  font-weight: 700;
  cursor: pointer;
}

.header__account {
  @include center;
  font-weight: var(--font-weight-regular);
  font-size: 17px;
}

.header__acount .icon {
  color: var(--button-blue);
}

.header__account {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 170px;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  color: #555555;
  border: 1px solid #555555a2;
  border-radius: 40px;
  height: 50px;
  cursor: pointer;

  svg {
    width: 45px;
    height: 45px;

    polygon {
      fill: #555555;
    }
  }
}

.header__account:hover,
.header__info:hover {
  background-color: #eeeeee;
}
