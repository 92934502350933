.company-data-dialog {
  height: 90vh;
  max-height: 1000px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company-data-dialog__body {
  padding-right: 10px;
  padding-left: 10px;
  max-height: 80%;
  overflow-y: scroll;
  margin: 0;
}

.company-data-dialog__body::-webkit-scrollbar {
  width: 10px;
}

.company-data-dialog__body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.company-data-dialog__body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.company-data-dialog__body:nth-child(1),
.company-data-dialog__body:nth-child(2) {
  grid-column: 1 / -1;
}

.company-data-dialog__body-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  border-bottom: 1px solid rgb(223, 223, 223);
  margin-bottom: 15px;

  &:last-of-type {
    border: none;
  }
}
.company-data-dialog__infobox {
  margin-bottom: 25px;
}

.company-data-dialog__body-fields-contact {
  border-top: 1px solid rgb(223, 223, 223);
  padding-top: 10px;
}

.company-data-dialog__body-fields-contact p {
  font-weight: 700;
}

.company-data-dialog__body-field {
  margin-bottom: 25px;
  position: relative;
}

.company-data-dialog__body-subtitle {
  font-weight: 700;
}

.company-data-dialog__body-field--validation {
  color: red;
  margin: 5px 0 0 10px;
  font-size: 12px;
  position: absolute;
}

.company-data-dialog__body-search-field-wrapper {
  margin-bottom: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(223, 223, 223);
}

.company-data-dialog__body-search-field-wrapper--buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.company-data-dialog__body-search-field-wrapper--button {
  width: 100%;
  background-color: var(--button-orange);
  opacity: 0.8;
  border: none;
  border-right: 2px solid orangered;
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--white);
  cursor: pointer;
}

.company-data-dialog__body-search-field-wrapper--button:last-of-type {
  border-right: none;
  border-radius: 0 5px 5px 0;
}

.company-data-dialog__body-search-field-wrapper--button:first-of-type {
  border-radius: 5px 0 0 5px;
}

.company-data-dialog__body-search-field-wrapper--button.active {
  opacity: 1;
}

.company-data-dialog__body-search-field-wrapper--input {
  display: flex;
}

.company-data-dialog__body-field-search {
  width: 100%;
  margin-right: 20px;
  position: relative;
}

.company-data-dialog__date-picker-field {
  margin-top: 21px;
}
