.pagination-bttns {
    button {
        margin: 0 12px;
        padding-bottom: 12px;
        border: none;
        color: rgb(104 104 104);
        background-color: transparent;
        font-size: 17px;
        font-weight: 600;
        cursor: pointer;

        &:not(:first-of-type, :last-of-type) {
            width: 35px;
            height: 35px;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .active {
        color: blue;
        font-weight: 800;
        box-shadow: inset 0 -4px 0 0 blue;

    }

    span {
        margin: 0 12px;
    }
    
    .hidden {
        display: none;
    }
}
