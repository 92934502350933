$arrow-position-right: 20px;

.drop-down {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.drop-down__wrapper {
  cursor: pointer;
  position: relative;
}

.drop-down__button {
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 15px;
  color: var(--button-blue);
  border: 2px solid var(--form-element-border-color);
  font-weight: 800;
}

.drop-down__menu {
  cursor: pointer;
  background-color: white;
  position: absolute;
  min-width: 180px;
  margin-top: 10px;
  right: 0;
  z-index: 2;
  padding: 10px;
  border-radius: 12px;
  border: 2px solid var(--form-element-border-color);
  display: flex;
  flex-direction: column;
}

.divider {
  background-color: var(--form-element-border-color);
  height: 2px;
  width: 100%;
}

.drop-down__menu--bottom {
  margin-top: 10px;
}

.drop-down__menu--top {
  bottom: 40px;
}

.drop-down__element {
  color: rgb(126 126 126);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f2f2f2;
  }
}

.drop-down__menu--bottom::before {
  content: "";
  display: block;
  position: absolute;
  right: $arrow-position-right;
  top: -10px;
  border-bottom: 10px solid var(--form-element-border-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.drop-down__menu--top::after {
  content: "";
  display: block;
  position: absolute;
  right: $arrow-position-right;
  bottom: -10px;
  border-top: 10px solid var(--form-element-border-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
