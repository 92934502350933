.button__float {
  cursor: pointer;
  font-size: 13px;
  width: 200px;
  border-radius: 20px;
  height: 40px;
  font-weight: 600;
  border: var(--form-element-border);
  border-color: var(--form-element-border-color);
  background-color: var(--white);
  color: var(--button-blue);

  &:disabled {
    border: none;
    background-color: var(--form-button-disabled-bg);
    color: var(--form-button-disabled-color);
    cursor: default;

    &:hover {
      background-color: var(--form-button-disabled-bg);
    }
  }
}

.button__float--blue {
  border: none;
  box-shadow: var(--button-shadow);
  background-color: var(--button-blue);
  color: #fff;

  &:hover {
    background-color: var(--button-blue-hover);
  }
}

.button__float--orange {
  border: none;
  box-shadow: var(--button-shadow);
  background-color: var(--button-orange);
  color: #fff;

  &:hover {
    background-color: var(--button-orange-hover);
  }
}
