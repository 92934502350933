@import "@faktoring/ui/src/themes/mixins";

.admin-request-preview {
  max-width: 800px;
  min-width: 700px;
  overflow-y: scroll;
}

.admin-request-preview::-webkit-scrollbar {
  width: 10px;
}

.admin-request-preview::-webkit-scrollbar-track {
  border-radius: 5px;
}

.admin-request-preview::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.admin-request-preview__buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.admin-request-preview__flatbutton-wide {
  width: 100%;
  @include center;
}

.admin-request-preview__flatbutton-total {
 margin-bottom: 16px;
}

.admin-request-preview__flatbutton-charged {
  margin-left: 16px;
  width: 100%;
}

.admin-request-preview__flatbutton-small {
  @include center;
  width: 30%;
}

.admin-request-preview__flatbutton-documentation {
  width: 100%;
  margin-left: 20px;
}

.admin-request-preview__additional_info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
}

.admin-request-preview__new-contract {
  display: flex;
  align-items: center;
  gap: 15px;
}

.admin-request-preview__invoice_list {
  padding: 30px;
  margin: 40px 0;
}

.admin-request-preview__additional-files {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.admin-request-preview__additional-files-name {
  font-size: 14px;
  margin-left: 10px;
}

.admin-request-preview__message {
  max-height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #ff3333;
  color: var(--white);
  padding: 10px 20px;
  transition: box-shadow .3s;
  cursor: pointer;
}

.admin-request-preview__file {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.admin-request-preview__additional_info-payment-data {
  margin: 15px 10px;
}

.admin-request-preview__error-message {
  color: #ff3333;
  font-size: 14px;
  margin: 0;
}