.side-nav-button__title {
  color: var(--white);
}

.side-nav-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;

  .side-nav-button__title {
    display: none;
  }

  &:hover {
    path,
    polygon,
    rect {
      fill: var(--button-orange);
    }
  }
}

.side-nav-button__icon {
  text-align: center;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);

  svg {
    width: 35px;

    &:hover {
      path,
      polygon,
      rect {
        fill: var(--button-orange);
      }
    }
  }
}

.side-nav-button__tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: max-content;
  color: white;
  font-size: 12px;
  background-color: var(--side-bar-tooltip-bg-color);
  border-radius: 10px;
  padding: 8px 15px 10px;
  left: 150%;
}

.side-nav-button__counter {
  background-color: var(--button-orange);
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 2px 12px;
}

.side-nav-button--open {
  .side-nav-button__tooltip {
    display: none;
  }

  .side-nav-button__title {
    display: contents;
  }
}

.side-nav-button:hover .side-nav-button__icon,
.side-nav-button:hover .side-nav-button__title {
  color: var(--button-orange);

  .side-nav-button__counter {
    color: var(--white);
  }
}

.side-nav-button__icon-active {
  svg {
    path {
      fill: var(--button-orange);
    }
    polygon {
      fill: var(--button-orange);
    }
    rect {
      fill: var(--button-orange);
    }
  }
}

.side-nav-button:hover .side-nav-button__tooltip {
  visibility: visible;
}

.side-nav-button:disabled {
  .side-nav-button__icon,
  .side-nav-button__title {
    color: var(--button-disabled-color);
  }

  .side-nav-button__tooltip {
    display: none;
  }
}
