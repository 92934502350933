.total-request {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 20px;
}

.total-request__buttons-wrapper {
    display: flex;
    justify-content: space-between;
}

.total-request__input-field {
    text-align: right;
    padding-right: 10px;
}