.layout-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.main-layout {
  display: flex;
  flex: 1 1 auto;
  min-height: 0;

  .content-wrapper {
    padding: 30px;
    flex: 1 1 auto;
    min-width: 0;
    background-color: rgb(242 243 247 / 100%);
    overflow: auto;
  }
}
