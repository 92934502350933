.input-field {
  height: var(--form-element-height);
  border-radius: var(--form-element-border-radius);
  border: var(--form-element-border);
  border-color: var(--form-element-border-color);
  font-size: var(--form-element-font-size);
  color: var(--form-element-content-color);
  width: 100%;
  padding-left: 10px;
}

.input-field--label {
  font-size: 14px;
  margin-left: 6px;
}

.input-field--invalid {
  border-color: var(--error);
}

.input-field__error {
  color: var(--error);
}

.input-field[type="number"]::-webkit-inner-spin-button,
.input-field[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
