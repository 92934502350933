.request-message {
  height: 150px;
}

.request-message__field-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.request-message__field-validation {
  color: red;
  margin: 5px 0 0 10px;
  font-size: 12px;
  position: absolute;
}

.request-message__field-text-box {
  height: 100%;
  resize: none;
  width: 100%;
  border: 0.5px solid black;
}
