.step {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  span {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--form-stepper-bg-color);
    color: var(--button-blue);
    font-weight: 500;
  }

  &:not(:last-child) {
    flex: 1;

    &::after {
      content: "";
      position: relative;
      height: 3px;
      background-color: var(--form-stepper-bg-color);
      flex: 1;
      margin: 0 10px;
    }
  }
}

.step--active {
  span {
    background-color: var(--button-blue);
    color: var(--white);
  }
}

.step--finished {
  span {
    background-color: var(--button-blue);
    color: var(--white);
    font-weight: 700;
  }

  &:not(:last-child) {
    &::after {
      background-color: var(--button-blue);
    }
  }
}

.step--disabled {
  span {
    background-color: var(--form-stepper-bg-color);
    color: var(--white);
  }
}
