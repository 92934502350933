.additional-doc__buttons-wrapper {
    display: flex;
    justify-content: space-between;
}

.additional-doc {
    max-width: 700px;
}

.additional-doc__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
