.download-invoices__body {
  max-width: 600px;
}

.download-invoices__field {
  margin-bottom: 25px;
  position: relative;
  width: 100%;
}

.download-invoices__info-box {
  margin-bottom: 25px;
}

.download-invoices__error-info-box-content {
  margin: 0;
}

.download-invoices__info-link {
  display: block;
  width: fit-content;
  margin-top: 6px;
  text-decoration: none;
  border-bottom: 2px solid var(--button-blue);
  font-size: 14px;
  color: var(--button-blue);
}

.download-invoices__add-api-key-button {
  width: 100%;
  margin-top: 15px;
  background-color: #eb5e5b;
  color: var(--white);
  border: none;

  &:hover {
    background-color: #eb4d4a;
  }
}

.download-invoices__change-key-button {
  border: none;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgba(#1473e6, 0.7);
  color: var(--white);
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: rgba(#1473e6, 0.8);
  }
}

.download-invoices__dates-wrapper {
  display: flex;
  gap: 15px;
}

.download-invoices__field--validation {
  color: red;
  font-size: 12px;
  margin: 5px 0 0 10px;
  position: absolute;
}

.download-invoices__no-invoice-message {
  color: red;
  font-size: 14px;
  margin-top: 12px;
}

.download-invoices__download-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-top: 10px;
}

.download-invoices__divider {
  margin-top: 20px;
  width: 30px;
  height: 2px;
  background-color: #7a7a7a;
}

.download-invoices__dialog-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}