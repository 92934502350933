.button__icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 60%;
  border: var(--form-element-border);
  border-color: var(--form-element-border-color);
  background-color: var(--white);
  color: var(--button-blue);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 35px;
    height: 35px;
  }

  &:disabled {
    border: none;
    background-color: var(--form-button-disabled-bg);
    color: var(--form-button-disabled-color);
    cursor: default;

    &:hover {
      background-color: var(--form-button-disabled-bg);
    }
  }
}

.button__icon--blue {
  border: none;
  box-shadow: var(--button-shadow);
  background-color: var(--button-blue);
  color: var(--white);

  &:hover {
    filter: brightness(70%);
  }
}

.button__icon--orange {
  border: none;
  box-shadow: var(--button-shadow);
  background-color: var(--button-orange);
  color: var(--white);

  &:hover {
    filter: brightness(70%);
  }
}
