.register__body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.register__field {
  position: relative;
}

.register__field--validation {
  color: red;
  font-size: 12px;
  margin: 5px 0 0 10px;
  position: absolute;
}
