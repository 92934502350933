.invoice-preview {
    min-width: 520px;
}

.invoice-preview__calculation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 20px;
    row-gap: 20px;
}

.invoice-preview__input-field {
    text-align: right;
    padding-right: 10px;
}

.invoice-preview__dates {
    display: flex;
    justify-content: space-between;
}

.invoice-preview__flatbutton__wide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.invoice-preview__file-list {
    margin-top: 20px;
}

.invoice-preview__file {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
}

.invoice-preview__file:first-of-type {
    padding-left: 0;
}

.invoice-payment__date-picker-field {
    margin-bottom: -8px;
}

.invoice-payment__body-field--validation {
    color: red;
    margin: 5px 0 0 10px;
    font-size: 12px;
    position: absolute;
}