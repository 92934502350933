.file-upload {
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--form-info-box-bg-color);
  box-shadow: var(--form-info-box-shadow);
}

.file-upload__icon {
  margin-bottom: 15%;
  color: var(--button-blue);
}

.file-upload__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--form-info-text-color);
}

.file-upload__text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.file-upload__input {
  text-decoration: underline;
  color: var(--button-blue);
  cursor: pointer;
}
