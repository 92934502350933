.payments__spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.payments__th {
  display: flex;
  align-items: center;
}

.payments__th-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.payments__th-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: flex;
    width: 25px;
  }

  polygon {
    fill: black;
  }
}

.payments__th-button:first-of-type {
  transform: rotate(180deg);
}

.payments__th-button:hover {
  background-color: rgb(240, 240, 240);
}

.payments__text-align-right {
  margin-right: 15px;
  text-align: right;
}