.user-data-settings__body-field {
    margin-bottom: 15px;
}

.user-data-settings__actions-group {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.user-data-settings__change-password-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
}

.user-data-settings__change-password-button {
    padding: 5px 10px;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: var(--button-blue);
    font-weight: 700;
    cursor: pointer;

    &:hover {
        background-color: rgba(#1473e6, 0.2);
    }
}