.file-list::-webkit-scrollbar {
  width: 10px;
}

.file-list::-webkit-scrollbar-track {
  border-radius: 5px;
}

.file-list::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.file-list {
  width: 100%;
  color: var(--button-blue);
  max-height: 200px;
  overflow-y: auto;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
}

.file-list__element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: var(--white);
  box-shadow: var(--form-info-box-shadow);
  border-radius: 20px;
  margin: 0 10px;
}

.file-list__details {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 40px;
}

.file-list__remove-button {
  all: unset;
  margin-top: 7px;
  cursor: pointer;
}

.file-list__document-type {
  background-color: var(--form-list-type-color);
  color: var(--form-button-disabled-color);
  padding: 5px 15px;
  border-radius: 17px;
  text-align: center;

  p {
    all: unset;
  }
}
