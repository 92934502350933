.report-preview__body {
  max-height: 700px;
  overflow-y: auto;
  padding-right: 10px;
}

.report-preview__body::-webkit-scrollbar {
  width: 10px;
}

.report-preview__body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.report-preview__body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.report-preview__invoice {
  min-width: 760px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--form-info-box-shadow);
  padding: 10px 30px;
  border-radius: 20px;
  margin: 25px 10px;
}

.report-preview__invoice-index {
  margin-right: 20px;
}

.report-preview__details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
