.side-nav__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: 100%;
  padding: 20px;
  background-color: var(--side-bar-bg-color);
}

.side-nav__divider {
  background-color: var(--side-bar-tooltip-bg-color);
  height: 2px;
  width: 100%;
}

.side-nav__list {
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.side-nav__list-element {
  list-style-type: none;
  padding: 0 10px;
}

.side-nav__toogle-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-nav__toogle-icon {
  color: var(--white);
  font-size: 30px;
}

.side-nav__icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 0 3px;

  button {
    border: none;
  }

  &:first-of-type {
    button {
      background-color: var(--button-blue);
    }
  }

  &:last-of-type {
    button {
      background-color: var(--button-orange);
    }
  }

  span {
    display: none;
  }
}

.side-nav__add-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 150px;
  gap: 32px;
  margin-bottom: 20px;
  padding: 0 10px 10px;
}

.side-nav--open {
  .side-nav__toogle-button {
    justify-content: flex-end;
  }

  .side-nav__add-container {
    padding: 0;
    gap: 12px;
  }

  .side-nav__toogle-box {
    justify-content: flex-end;
    width: 250px;
  }

  .side-nav__icon-box {
    span {
      display: contents;
      color: white;
    }

    &:hover {
      button {
        filter: brightness(70%);
      }

      span {
        color: var(--button-orange);
      }
    }
  }
}
