.requests {
  height: 100%;
  padding-bottom: 20px;
  position: relative;
}

.requests__text-align-right {
  text-align: right;
  margin: 0;
  padding-right: 15px;
  justify-content: flex-end;
}

.requests__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.requests__th {
  display: flex;
  align-items: center;
}

.requests__th-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.requests__th-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: flex;
    width: 25px;
  }

  polygon {
    fill: black;
  }
}

.requests__th-button:first-of-type {
  transform: rotate(180deg);
}

.requests__th-button:hover {
  background-color: rgb(240, 240, 240);
}

.requests__text-align-right {
  margin: 0;
  padding-right: 15px;
  text-align: right;
}

.requests__company-name,
.requests__buyer-name {
  position: relative;
}

.requests__company-name:hover .requests__limit {
  visibility: visible;
}

.requests__buyer-name:hover .requests__limit {
  visibility: visible;
}

.requests__limit {
  visibility: hidden;
  position: absolute;
  bottom: -45px;
  background-color: rgb(233, 233, 233);
  padding: 5px;
  border-radius: 5px;
  z-index: 2;
}

.requests__limit::after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  transform: rotate(45deg);
  background-color: rgb(233, 233, 233);
  z-index: -1;
}

.request__action-icon,
.request__action-icon svg {
  width: 30px;
  height: 30px;
}

.request__action-icon svg path {
  fill: rgb(145, 145, 145);
}