.buyers__th {
  display: flex;
  align-items: center;
}

.buyers__text-align-right {
  justify-content: flex-end;
  text-align: right;
}

.buyers__th-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.buyers__th-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: flex;
    width: 25px;
  }

  polygon {
    fill: black;
  }
}

.buyers__th-button:first-of-type {
  transform: rotate(180deg);
}

.buyers__th-button:hover {
  background-color: rgb(240, 240, 240);
}

.buyers__text-align-right {
  margin: 0;
  padding-right: 15px;
  text-align: right;
}

.buyers__spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyers__action-icon, 
.buyers__action-icon svg {
  width: 30px;
  height: 30px;
}

.buyers__action-icon svg path {
  fill: rgb(145, 145, 145);
}