.search-bar__form {
    display: flex;
    align-items: center;
    height: 30px;
    position: relative;
    
    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
    }
}

.search-bar__input {
    width: 200px;
    height: 100%;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;
    padding-left: 35px;
    transition: 0.2s;
}

.search-bar__input:focus,
.search-bar__input:active {
  outline: none;
  border: 1px solid var(--button-blue);
}

.search-bar__submit-button {
    margin-left: 5px;
    padding: 5px 10px;
  background-color: var(--button-blue);
  border-radius: 10px;
  color: white;
  border: none;
  cursor: pointer;
}

.search-bar__submit-button:disabled {
    background-color: rgb(196, 196, 196);
}
