.new-bank-account__field {
  margin-bottom: 30px;
  position: relative;
}

.new-bank-account__field--validation {
  color: red;
  font-size: 12px;
  margin: 5px 0 0 10px;
  position: absolute;
}
