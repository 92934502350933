.info-dialog {
  max-width: 1000px;
  height: 90vh;
  max-height: 1100px;
}

.info-dialog__body {
  max-height: 90%;
  overflow-y: scroll;
  margin: 0;
}

.info-dialog__info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

.info-dialog__info-wrapper p {
    max-width: 80%;
    margin-bottom: 15px;
}

.info-dialog__info-wrapper img {
    text-align: center;
    width: 30vw;
    max-width: 550px;
}

.info-dialog__body::-webkit-scrollbar {
  width: 10px;
}

.info-dialog__body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.info-dialog__body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.title-index-button {
  border: none;
  border-bottom: 1px solid rgb(161, 161, 161);
  margin-top: 10px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.info-dialog__links-wrapper {
  max-width: 80%;
  margin: 0 auto;
}

.info-dialog__info-link {
  display: block;
  width: fit-content;
  margin-top: 6px;
  text-decoration: none;
  border-bottom: 2px solid var(--button-blue);
  font-size: 14px;
  color: var(--button-blue);
}