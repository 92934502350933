.message-preview__buttons-wrapper {
    display: flex;
    justify-content: space-between;
}

.message-preview__button-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}