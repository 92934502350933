.company-data-settings {
  height: 90vh;
  max-height: 1100px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company-data-settings__body {
  padding-right: 10px;
  padding-left: 10px;
  max-height: 80%;
  overflow-y: scroll;
  margin: 0;
}

.company-data-settings__body::-webkit-scrollbar {
  width: 10px;
}

.company-data-settings__body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.company-data-settings__body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.company-data-settings__body-fields {
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  border-bottom: 1px solid rgb(223, 223, 223);
  margin-bottom: 15px;

  &:last-of-type {
    border: none;
  }
}

.company-data-settings__body-field {
  margin-bottom: 25px;
  position: relative;
}

.company-data-settings__body-subtitle {
  font-weight: 700;
}

.company-data-settings__field--validation {
  color: red;
  margin: 5px 0 0 10px;
  font-size: 12px;
  position: absolute;
}

.company-data-settings__file-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.company-data-settings__file-button {
  display: flex;
  align-items: center;
}

.company-data-settings__file {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.company-data-settings__file:first-of-type {
  padding-left: 10px;
}

.company-data-settings__actions-group {
  display: flex;
  justify-content: space-between;
}

.company-data-settings__delete-button {
  max-height: 25px;
  border-radius: 50px;
  border: none;
  color: red;
  font-weight: 700;
  cursor: pointer;
}

.company-data-settings__delete-button:hover {
  background-color: rgb(211, 211, 211);
}

.company-data-settings__save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
}

.company-data-settings__body-field--validation {
  color: red;
  margin: 5px 0 0 10px;
  font-size: 12px;
  position: absolute;
}