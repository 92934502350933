.date-picker {
  width: 100%;
}

.date-picker__button {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  letter-spacing: 0.5px;
  background-color: var(--white);
  height: var(--form-element-height);
  border-radius: var(--form-element-border-radius);
  border: var(--form-element-border);
  border-color: var(--form-element-border-color);
  font-size: var(--form-element-font-size);
  color: var(--form-element-content-color);
  
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border: none;
    border-radius: var(--form-element-border-radius);
  }

  .date-picker__button-icon {
    position: absolute;
    right: 0;
    right: 5px;
    font-size: 25px;
    display: flex;

    svg {
      height: 30px;

      path {
        fill: var(--button-blue);
      }
    }
  }

  &:hover {
    background-color: var(--form-select-list-element-hover);
  }

  &:disabled {
    color: var(--form-button-disabled-color);
    background-color: var(--form-button-disabled-bg);

    &:hover {
      background-color: var(--form-button-disabled-bg);
    }

    .date-picker__button-icon {
      color: var(--form-button-disabled-color);
    }
  }
}

.date-picker__text--placeholder {
  color: var(--form-info-text-color);
}

.date-picker__title {
  text-align: center;
  margin-bottom: 5px;
}

.date-picker__label {
  font-size: 14px;
  margin-left: 6px;
}
