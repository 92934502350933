.info-box {
  background-color: var(--form-info-box-bg-color);
  width: 100%;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: var(--form-info-box-shadow);
  position: relative;

  h4 {
    margin: 0;
    color: var(--button-blue);
  }
}

.info-box__content {
  color: var(--form-info-text-color);
  font-weight: 500;
}

.info-box__left-border {
  background-color: var(--button-blue);
  margin: auto 0;
  width: 7px;
  height: 60%;
  position: absolute;
  left: 0;
  top: 20%;
  border-top-right-radius: 80px 150px;
  border-bottom-right-radius: 80px 150px;
}

.info-box__error {
  background-color: #cc0000;

  h4 {
    color: var(--white);
  }
}

.info-box__left-border-error {
  background-color: #ef5350;
}

.info-box__content-error {
  background-color: #cc0000;
  color: var(--white);
}
