.invoice-payments-preview {
    min-width: 520px;
    max-height: 600px;
    overflow-y: auto;
    padding-right: 20px;
}

.invoice-payments-preview::-webkit-scrollbar {
    width: 10px;
}

.invoice-payments-preview::-webkit-scrollbar-track {
    border-radius: 5px;
}

.invoice-payments-preview::-webkit-scrollbar-thumb {
    background-color: var(--button-blue);
    border-radius: 5px;
}

.invoice-payments-preview__payment:not(:last-child) {
    border-bottom: 1.5px solid rgb(219, 219, 219);
}