@font-face {
  font-family: NunitoSans;
  font-weight: 400;
  src: url('../assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/NunitoSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 600;
  src: url('../assets/fonts/NunitoSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 600;
  font-style: italic;
  src: url('../assets/fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 700;
  src: url('../assets/fonts/NunitoSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 700;
  font-style: italic;
  src: url('../assets/fonts/NunitoSans-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 800;
  src: url('../assets/fonts/NunitoSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: NunitoSans;
  font-weight: 800;
  font-style: italic;
  src: url('../assets/fonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
}

:root {
  --font-weight-regular: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
}