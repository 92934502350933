.new-invoice__dialog-body::-webkit-scrollbar {
  width: 10px;
}

.new-invoice__dialog-body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.new-invoice__dialog-body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.new-invoice__step-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-invoice__row--two-fields {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.new-invoice__row--three-fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.new-invoice__row-button {
  width: max-content;
}

.new-invoice__file-upload-content {
  text-align: center;
}

.new-invoice__field {
  margin-bottom: 25px;
  position: relative;
  width: 100%;
}

.new-invoice__number-field {
  text-align: right;
  padding-right: 10px;
}

.new-invoice__field--validation {
  color: red;
  font-size: 12px;
  margin: 5px 0 0 10px;
  position: absolute;
}

.new-invoice__field--information {
  color: red;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 0 10px;
}

.new-invoice__dialog-actions-group {
  margin-top: 15px;
}
