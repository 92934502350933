.contracts__spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contracts__actions-button {
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    margin-top: 15px;
  }

  p {
    margin-top: 30px;
  }
}

.contracts__actions-button--create-contract {
  width: 200px;
}

.contracts__select-wrapper {
  width: 200px;
}

.contracts__action-icon,
.contracts__action-icon svg {
  width: 30px;
  height: 30px;
}

.contracts__action-icon svg path {
  fill: rgb(145, 145, 145);
}

.contracts__th {
  display: flex;
  align-items: center;
}

.contracts__th-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.contracts__th-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: flex;
    width: 25px;
  }

  polygon {
    fill: black;
  }
}

.contracts__th-button:first-of-type {
  transform: rotate(180deg);
}

.contracts__th-button:hover {
  background-color: rgb(240, 240, 240);
}

.contracts__text-align-right {
  margin-right: 15px;
  text-align: right;
}