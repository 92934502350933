.buyer-edit {
  height: 90vh;
  max-height: 1100px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buyer-edit__body {
  padding-right: 10px;
  padding-left: 10px;
  max-height: 80%;
  overflow-y: scroll;
  margin: 0;
}

.buyer-edit__body::-webkit-scrollbar {
  width: 10px;
}

.buyer-edit__body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.buyer-edit__body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.buyer-edit__body-field {
  margin-bottom: 25px;
  position: relative;
}

.buyer-edit__body-fields {
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  border-bottom: 1px solid rgb(223, 223, 223);
  margin-bottom: 15px;

  &:last-of-type {
    border: none;
  }
}

.buyer-edit__body-fields-contact {
  border-top: 1px solid rgb(223, 223, 223);
  margin-bottom: 15px;
  padding-top: 15px;
}

.buyer-edit__body-fields-contact-subtitle {
  font-weight: 700;
}

.buyer-edit__body-field--validation {
  color: red;
  margin: 5px 0 0 10px;
  font-size: 12px;
  position: absolute;
}

.buyer-edit__body-fields-limit {
  border-top: 1px solid rgb(223, 223, 223);
  padding: 15px 0;
  margin-bottom: 10px;
  position: relative;
}

.buyer-edit__body-field-limit {
  width: 50%;
}

.buyer-edit__dialog-actions {
  margin-top: 25px;
}