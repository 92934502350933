@import "@faktoring/ui/src/themes/mixins";

.header-link {
  @include center;
  border: none;
  width: 150px;
  margin-left: 10px;
  background-color: transparent;
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  color: #7e7e7e;
  text-decoration: none;
  cursor: pointer;

  svg {
    min-height: 40px;
    height: 40px;

    path,
    polygon {
      fill: #b1b1b1;
    }
  }
}

.header-link:first-of-type {
  width: 145px;
}

.header-link:not(:first-of-type) {
  svg {
    margin-right: 8px;
  }
}

.header-link:last-of-type {
  margin-left: 0;
  height: 80px;
}

.header-link.active {
  box-shadow: inset 0 -5px 0 0 var(--button-blue);
  color: var(--button-blue);

  svg {
    fill: var(--button-blue);

    path,
    polygon {
      fill: var(--button-blue);
    }
  }
}
