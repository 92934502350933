.new-request__dialog {
  width: 700px;
  max-height: 95vh;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-request__dialog-body {
  padding-right: 10px;
  padding-left: 10px;
  max-height: 80%;
  overflow-y: auto;
  margin: 0;
  width: 100%;
}

.new-request__step-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-request__row--two-fields {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.new-request__step-container-row {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.new-request__row--three-fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.new-request__step-container-infobox {
  margin-bottom: 30px;
}

.new-request__row-button {
  width: max-content;
}

.new-request__file-upload-content {
  text-align: center;
}

.new-request__field {
  margin-bottom: 25px;
  position: relative;
  width: 100%;
}

.new-request__field-divider {
  width: 100%;
  text-align: center;
}

.new-request__field--validation {
  color: red;
  font-size: 12px;
  margin: 5px 0 0 10px;
  position: absolute;
}

.new-invoice__field--information {
  color: red;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-request__add-invoice-button {
  width: 100%;
}

.new-request__invoices-list-wrapper {
  max-height: 25vh;
  overflow-y: scroll;
}

.new-request__invoices-list-wrapper::-webkit-scrollbar,
.new-request__dialog-body::-webkit-scrollbar {
  width: 10px;
}

.new-request__invoices-list-wrapper::-webkit-scrollbar-track,
.new-request__dialog-body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.new-request__invoices-list-wrapper::-webkit-scrollbar-thumb,
.new-request__dialog-body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.new-request__dialog-actions-group {
  margin-top: 15px;
}
