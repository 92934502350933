.invoices-list::-webkit-scrollbar {
  width: 10px;
}

.invoices-list::-webkit-scrollbar-track {
  border-radius: 5px;
}

.invoices-list::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.invoices-list {
  width: 100%;
  color: var(--button-blue);
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
}

.invoices-list__number-and-amount {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  min-width: 55%
}

.invoices-list__status-and-cta {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  min-width: 35%
}

.invoices-list__remove-button {
  all: unset;
  margin-top: 7px;
  cursor: pointer;
}

.invoices-list__element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: var(--white);
  box-shadow: var(--form-info-box-shadow);
  border-radius: 20px;
  margin: 0 10px;
  min-width: 500px;
}

.invoices-list__details {
  background-color: var(--button-blue);
  color: var(--white);
  border: none;
  padding: 6px 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  cursor: pointer;
}
