.input-show-and-hide__wrapper {
    position: relative;
}

.input-show-and-hide__button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 40px;
    min-width: 80px;
    padding: 0 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--form-element-border-color);
    border-radius: var(--form-element-border-radius);
    right: 0;
    bottom: 0;
    cursor: pointer;
}