.users__text-align-right {
  text-align: right;
  margin: 0 15px;
}

.users__action-icon, 
.users__action-icon svg {
  width: 30px;
  height: 30px;
}

.users__action-icon svg path {
  fill: rgb(145, 145, 145);
}

.users__spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
