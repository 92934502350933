.new-contract {
  max-height: 90vh;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-contract__body {
  height: 100%;
  max-height: 80%;
  margin: 0;
  margin-bottom: 30px;
  overflow-y: scroll;
}

.new-contract__dialog-body-step-one {
  width: 80vw;
}

.new-contract__body::-webkit-scrollbar {
  width: 10px;
}

.new-contract__body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.new-contract__body::-webkit-scrollbar-thumb {
  background-color: var(--button-blue);
  border-radius: 5px;
}

.new-contract__step-one {
 max-width: 500px ;
}

.new-contract__fields {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  border-bottom: 1px solid rgb(184, 184, 184);
  margin-bottom: 15px;
  padding-bottom: 25px;

  &:last-of-type {
    border: none;
  }
}

.new-contract__field {
  position: relative;
  width: 220px;
}

.new-contract__hidden-field {
  display: none;
}

.new-contract__field--validation {
  color: red;
  margin: 5px 0 0 10px;
  font-size: 12px;
  position: absolute;
}

.new-contract__step-three {
  overflow: scroll;
}