@import "../../themes/mixins";

.table-wrapper {
  @include prevent-selection;

  width: 100%;
  max-width: 2000px;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 50px;
}

.table__head {
  background-color: var(--white);
  box-shadow: 0 0 13px 1px rgb(0 0 0 / 23%);
  border-radius: 15px;
}

.table__head-element {
  text-align: left;
  padding: 10px;
  padding-left: 30px;
  color: rgb(126 126 126);
  font-size: 19px;
  font-style: italic;
  font-weight: 600;

  &:not(:first-of-type) {
    padding-left: 0;
  }

  &:first-of-type {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.table__body {
  &::before {
    content: "-";
    display: block;
    line-height: 10px;
    opacity: 0;
  }
}

.table__body-element {
  text-align: left;
  padding: 20px 5px;
  font-size: 17px;
  color: rgb(94 94 94);
  font-weight: 600;

  &:first-of-type {
    padding-left: 30px;
    color: black;
  }
}

.table__body-row {
  cursor: pointer;
  border-radius: 20px;

  &:hover {
    background-color: var(--white);
    box-shadow: 0 0 8px 1px rgb(0 0 0 / 13%);
  }
}

.table__body-row--new-buyer {
  background-color: #e8e8e8;
}